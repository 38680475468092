.Loader {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.39);
    z-index: 9999999;

    img {
        height: 150px;
    }
} 