@font-face {
  font-family: "Montserrat-Bold";
  src: url("shared/assets/fonts/Montserrat/Montserrat-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Montserrat-Medium";
  src: url("shared/assets/fonts/Montserrat/Montserrat-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "Montserrat-Regular";
  src: url("shared/assets/fonts/Montserrat/Montserrat-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Montserrat-Light";
  src: url("shared/assets/fonts/Montserrat/Montserrat-Light.ttf") format("truetype");
}

@font-face {
  font-family: 'Metronic Pro Condensed';
  src: url('shared/assets/fonts/MetronicProCondensed/MetronicProCondensed-Light.eot') format('embedded-opentype'),
      url('shared/assets/fonts/MetronicProCondensed/MetronicProCondensed-Light.woff') format('woff'),
      url('shared/assets/fonts/MetronicProCondensed/MetronicProCondensed-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

html,
body {
  color: #222;
  margin: 0;
  padding: 0;
  font-family: Montserrat-Regular, -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.row {
  margin-left: -12px !important;
  margin-right: -12px !important;
}
.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12,
.col,
.col-auto,
.col-sm-1,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm,
.col-sm-auto,
.col-md-1,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md,
.col-md-auto,
.col-lg-1,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg,
.col-lg-auto,
.col-xl-1,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl,
.col-xl-auto,
.container {
  padding-left: 12px !important;
  padding-right: 12px !important;
}

@media (min-width: 1200px) {
  .container {
    max-width: 1128px !important;
  }
}

@media (max-width: 580px) {
  .container {
    padding-left: 22px !important;
    padding-right: 22px !important;
  }
}
