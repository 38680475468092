@import "../src/variables.scss";

.component-header-with-animation {
  font-family: "Montserrat-Bold";
  font-size: 28px;
  line-height: 34px;
  color: $blackColor;
  &:hover {
    .green-border {
      width: 100%;
    }
  }
}

.description-text {
  font-family: "Montserrat-Medium";
  font-size: 16px;
  line-height: 24px;
}

.flex {
  display: flex !important;
}

.align-v {
  align-items: center;
}

.block-title {
  font-size: 28px;
  line-height: 34px;
  color: #222;
  font-family: "Montserrat-Bold";
}

.no-link {
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }
}

@media (max-width: 767px) {
  .component-header-with-animation {
    font-size: 22px;
  }
  .justify-center-md {
    justify-content: center;
  }
  .text-center-md {
    text-align: center;
  }
  .block-title,
  .title {
    font-size: 24px !important;
    line-height: 120% !important;
  }

  br {
    display: none;
  }
}

@media (max-width: 360px) {
  .Intiqol {
    width: 360px;
  }
}
